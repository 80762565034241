

import { getAuth, createUserWithEmailAndPassword,signOut, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

import app from "../../firebaseConfig";
import * as actionTypes from './actionTypes';
import { user_from_auth } from "../../shared/authUtility";
import { get_subscription_data } from "../../shared/utility";
import { getCompanyData } from "../../shared/companyDataUtility";



const gAuth = getAuth(app);


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};
 



export const authSuccess = ( userId, subscription) => {
   
    return {
        type: actionTypes.AUTH_SUCCESS,
        userId: userId, 
        subscription: subscription
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};



export const logout = () => {

   

    localStorage.setItem('logout', true);
    
    signOut(gAuth)
    .then(()=>{
        
        // to set auth credentials to null : 
        authSuccess(null , null, null);
    
        localStorage.removeItem('token');
        //localStorage.removeItem('expirationDate');  // is will be replaced with admin email with if else statement
        localStorage.removeItem('userId');
     })
     .catch((error) => {
        
        alert('sign out error auth.js logout');  // error handle has to be done 
      });
      
    
     
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};


export const companyData = ( obj ) => {
    return {
        type: actionTypes.COMPANY_DATA,
        companyData: obj
    };
};


export const existingOrders = ( arr ) => {
    return {
        type: 'orderExists',
        orders: arr
    };
};


export const auth =  ( email , password, obj ) => {

      return dispatch =>{

        dispatch( authStart() );
        
        if( !obj.isSignUp  ){        
       
         signInWithEmailAndPassword( gAuth, email, password )
         .then((userCredential)=>{

            const user = userCredential.user;   
            localStorage.setItem( 'userId' , user.uid );
           
            getCompanyData(user.uid)
            .then(( result )=>{

                get_subscription_data( user.uid )
                .then((customer)=>{
                  
                        if(customer.status === 'active'){
                              
                            dispatch( authSuccess(  user.uid , true ) );
                            dispatch( companyData(result) )
                 
                          }else{
                            dispatch( authSuccess( user.uid , false ) );
                            dispatch( companyData(result) )
                          }
    
                     
                })
                .catch(()=>{
                    dispatch( authSuccess( user.uid , false) );
                    dispatch( companyData(result) )
                })

            })
            .catch(()=>{
                // login works even there is no company data: (appropriate for the first time login )
                dispatch( authSuccess( user.uid , false) );
            })

            

            //if we dont check subscription uncomment out this,and comment out all codes associated with get_subscription_data above: 
            
            // dispatch( authSuccess( user.stsTokenManager.accessToken , user.uid , true ) );

            
            
         })
        .catch((error)=>{

            const errorCode = error.code;
            const errorMessage = error.message;
           
           // Show error message
           // Check if the error code is one of the firebase/auth codes
           if (errorCode.startsWith('auth/')) {
            
               dispatch( authFail(errorCode) )
           
           } else {
               // for network error : 
              dispatch( authFail(errorCode) )
            }

        })  
            
           
            
      
      
        } else {
         
           
             createUserWithEmailAndPassword( gAuth, email, password )
             .then(()=>{
                 

                 sendEmailVerification( gAuth.currentUser )
                 .then(()=>{
                    
                    dispatch( authSuccess(gAuth.currentUser.accessToken, gAuth.currentUser.uid) )
                 })


             })
             .catch(( error )=>{

                const errorCode = error.code;
               const errorMessage = error.message;
            
           // Show error message
           // Check if the error code is one of the firebase/auth codes
           if (errorCode.startsWith('auth/')) {
            
               dispatch( authFail(errorCode) )
           
           } else {
               // for network error : 
              dispatch( authFail(errorCode) )
            }

             })
            
            
      
            }
      
      }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};



export const authCheckState = () => {
    return dispatch => {
        
        const userId = localStorage.getItem('userId')
        dispatch( authSuccess( userId , true) );  // prevents products error while reloading other pages, 'true' to 
        
         user_from_auth()
        .then((currentUser)=>{
              const user = currentUser.data.auth.currentUser ; 
               
              getCompanyData(user.uid)
              .then(( result )=>{
  
                  get_subscription_data( user.uid )
                  .then((customer)=>{
                    
                          if(customer.status === 'active'){
                            
                              dispatch( authSuccess(  user.uid , true ) );
                              dispatch( companyData(result) )
                   
                            }else{
                              dispatch( authSuccess( user.uid , true ) );
                              dispatch( companyData(result) )
                            }
      
                       
                  })
                  .catch(()=>{
                      dispatch( authSuccess( user.uid , true) );
                      dispatch( companyData(result) )
                  })
  
              })
              .catch(()=>{
                  // login works even there is no company data: (appropriate for the first time login )
                  dispatch( authSuccess( user.uid , true) );
              })
  
              //if we dont check subscription uncomment out this,and comment out all codes associated with get_subscription_data above: 
              
              // dispatch( authSuccess( user.stsTokenManager.accessToken , user.uid , true ) );
  
              
        
            })
            .catch(()=>{
              dispatch(logout()); 
            })

    };
};


