import axios from 'axios';

const instance = axios.create({
    // baseURL: 'http://127.0.0.1:5001/smenu-746d5/us-central1/app2'
    
    baseURL: 'https://app2-vxyprcgejq-uc.a.run.app'
    // baseURL: 'http://localhost:4242'
   
});

export default instance; 