import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import * as actions from '../../../store/actions/index' ;


const NavigationItems = ( props ) => {
    
    const [autoSignF , setAutoSignF] = useState(false) ;
    const [autoSignT , setAutoSignT] = useState(true) ;
    
    



    return(
        <div className={classes.NavigationItemsContainer}>
     <ul className={classes.NavigationItems} >
         
           <NavigationItem  link="/"    
                                        isAuthenticated = { props.isAuthenticated } 
                                        isAdmin= { props.isAdmin }
                                        
                                        exact >
                                          
              Home
           </NavigationItem> 
          
        
        {/* {props.isAuthenticated ? <NavigationItem link="/menu"  isAdmin= {props.isAdmin} autoSign = {autoSignF} >Our Menu</NavigationItem>:""} */}
        
        {/* {  props.isAuthenticated && props.isAdmin !==props.userId  ? <NavigationItem link="/tables">Tables</NavigationItem> : null}
         */}

   {  props.isAdmin && props.isAuthenticated  ? <NavigationItem link="/tables" isAdmin= {props.isAdmin} autoSign = {autoSignF} >Tables</NavigationItem> : null}
   {  props.isAdmin && props.isAuthenticated  ? <NavigationItem link="/dashboard" isAdmin= {props.isAdmin} autoSign = {autoSignF} >Dashboard</NavigationItem> : null}
   {  props.isAdmin && props.isAuthenticated  ? <NavigationItem link="/payments" isAdmin= {props.isAdmin} autoSign = {autoSignF} > Payments </NavigationItem> : null} 
       
       

        { props.isAuthenticated
            ?<NavigationItem link="/logout" isAdmin= {props.isAdmin} autoSign = {autoSignF} >Logout</NavigationItem> 
            : <NavigationItem link="/auth" isAdmin= {props.isAdmin} autoSign = {autoSignF}  >Login</NavigationItem> }
    </ul>
    </div>
);
        }


const mapStateToProps = state => {
   return {
    
    userId: state.auth.userId 
     };
    };


const mapDispatchToProps = dispatch => {
    return {
      
      // on_auth_client: ( email, password, isSignup ) => dispatch( actions.auth_client( email, password, isSignup ) ),
    
    };
  };
  
  export default withRouter( connect( mapStateToProps, mapDispatchToProps )( NavigationItems ) );


//good to know:
// props from Toolbar.js


// things to do:
// move redux code to layout.js 