import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null, //no use
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: '/' ,
    subscription: false, // new 
    orders: [], //new

    data: {     // new
        accountActivation: false,
        accountName: "",
        city: "",
        companyName: "",
        country: "",
        currency: null,
        imageName: "",
        imageToken: "",
        paymentSystemStatus: false,
        postCode: "",
        productEditingMode: false,
        streetName: "",
        streetNumber: "",
        
    }
   
};


const companyData = ( state, action ) => {
    
    return updateObject( state, { data: action.companyData } );
};



const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        userId: action.userId,
        error: null,
        loading: false,
        subscription: action.subscription // new
    
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null, admin:false });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const existingOrders = (state, action) => {
    return updateObject(state, { orders: action.orders })
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.COMPANY_DATA: return companyData(state, action);
        case 'orderExists' : return existingOrders( state, action );  // no use , kept it for referrence
        default:
            return state;
    }
};

export default reducer;