import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'; 
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import authReducer from './store/reducers/auth';
import paymentReducer from './store/reducers/payment' ;
import companyDataReducer from './store/reducers/editMode' ; 
// import { editMode } from './store/actions';


//const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
  const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    payment: paymentReducer,
    companyData: companyDataReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App  />
        </BrowserRouter>
    </Provider>
);


const container = document.getElementById('root');
const root = createRoot(container);
root.render(app)
// ReactDOM.render( app, document.getElementById( 'root' ) );
// registerServiceWorker();
