import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';




const initialState = {
    data: {
        accountActivation: false,
        accountName: "",
        city: "",
        companyName: "",
        country: "",
        currency: null,
        imageName: "",
        imageToken: "",
        paymentSystemStatus: false,
        postCode: "",
        productEditingMode: false,
        streetName: "",
        streetNumber: "",
        
    } 
};

const companyData = ( state, action ) => {
    
    return updateObject( state, { data: action.companyData } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.COMPANY_DATA: return companyData(state, action);
        default:
            return state;
    }
};

export default reducer;