import React from 'react';

import classes from './DrawerToggle.css';
import classesNeu from '../../../../shared/css/neumorphism.css'
const drawerToggle = (props) => (
    <div className={classes.DrawerToggle + ' ' + classesNeu.neumorphism} onClick={props.clicked}>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export default drawerToggle; 