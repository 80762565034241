import React from 'react';

import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.css';
import classesNeu from '../../../shared/css/neumorphism.css' ;
import Backdrop from '../../UI/Backdrop/Backdrop'; 
import Aus from '../../../hoc/Aus/Aus';
 
const sideDrawer = ( props ) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open, classesNeu.neumorphism ];
    }
    return (
        <Aus>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ') } onClick={props.closed}>
                <div className={classes.Logo}>
                    <Logo />
                </div>
                <nav>
                    <NavigationItems isAuthenticated={ props.isAuth } isAdmin = { props.isAdmin } />
                </nav>
            </div>
        </Aus>
    );
};

export default sideDrawer;