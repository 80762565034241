
import { getAuth, onAuthStateChanged , createUserWithEmailAndPassword,  signOut, 
         signInWithEmailAndPassword , updateEmail , updatePassword , sendPasswordResetEmail } from "firebase/auth";
import app from "../firebaseConfig";


const auth = getAuth(app);

export const send_password_reset_email = async (email) => {
    try{
        await sendPasswordResetEmail( auth, email ) ;
    } catch(error) {
        throw error ;
    }
 } ; 



 export const user_from_auth = async () => {
    const auth = getAuth(app);
  
    // Wrap the onAuthStateChanged in a promise
    const data = await new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(user); // Resolve the promise with the user object
        } else {
          reject("NoUser"); // Reject the promise with an error message
        }
      });
    });
  
    // If the promise is resolved, you can access the user's email
    
     return { data }
  };




  


export const update_email =  async ( newEmail ,email,  password) => {
  
  if(!email || !password || !newEmail){
    //  return ;  //with simple return , we to to .then block
     //  throwing new err for catch block 
     throw new TypeError("Invalid input");
  }
 
  const user = auth.currentUser;
 
   try{

     await signInWithEmailAndPassword(auth, email, password)
     await updateEmail(user, newEmail);
   
  } catch(error) {
      
      throw error ;

   }
  

} ; 


// we dont use this:
export const update_password =  async ( newPassword ,email,  password) => {
  
  if(!email || !password || !newPassword){
     return
  }
 
  const user = auth.currentUser;

   try{

   
   await signInWithEmailAndPassword(auth, email, password)
     await updatePassword(user, newPassword);
   
  } catch(error) {
      
      throw error ;

   }
  

} ; 