import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.css';
import classesNeu from '../../../../shared/css/neumorphism.css' ;
const reload=()=>{
    // window.location.reload()
}

const navigationItem = ( props ) => (
   
    <li className={ classes.NavigationItem + ' '+ classesNeu.neumorphism } onClick={ !props.isAdmin && props.autoSign ? ()=>props.autoSignIn(props.isAuthenticated) : null} >
        <NavLink 
            to={props.link}
            exact={props.exact}
            activeClassName={classes.active}>{props.children}</NavLink>
    </li>
);

export default navigationItem;

