
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const PASSWORD_RESET_SUCCESS= 'PASSWORD_RESET_SUCCESS';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const PAYMENT_START = 'PAYMENT_START' ;
export const COMPANY_INFO = 'COMPANY_INFO' ;
export const COMPANY_DATA = 'COMPANY_DATA' ;

