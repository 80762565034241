
import {getDatabase , ref , set,  push, get, remove, update } from "firebase/database";

import { getAuth, signInWithEmailAndPassword , updateEmail , updatePassword} from "firebase/auth";
import app from "../firebaseConfig";
import instance from "../axiosBase";


const db = getDatabase(app) ;
const auth = getAuth(app)

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}

// to hide and show overflow-y scroll bar: 
export const overflowHidden = ( bl=false , cls)  => {
  
  bl ? document.body.classList.add(cls) : document.body.classList.remove(cls);
 
}

// Define a function that checks the input value
export const check_input_validity = (value, event) => {
  // Define a regular expression that matches all the characters that are not alphanumeric, whitespace, or Latin with diacritics
  const regex = /[^\w\s\u00C0-\u017F,.()!?:']/gi;
  // const regex = /[\w\s\u00C0-\u017F,.!()]/g;


  // Test the value against the regular expression
  const testResult = regex.test(value);
  // If the test result is true, show an alert with the matched characters and prevent the default behavior of the input element
  if (testResult) {
    // Get the matched characters using the match method
    const matchedChars = value.match(/[^,\.\!\(\)]/g); 
    // const matchedChars = value.match(regex);
    // Join the matched characters into a string using the join method
    const matchedString = matchedChars.join("");
    // Show an alert with the matched string
      alert("The following characters are not allowed: " + matchedString);
    // Prevent the default behavior of the input element using the event parameter
    // event.preventDefault();
    // Use the event.target.value property to get the value of the input element before the change
    // value = event.target.value;
    return false ;
  }else{
    return true
  }
  // Return the sanitized value
  // return value;
};







// This function returns true if the input is a positive round number, otherwise false
export const isValidPositiveRoundNumber =  (input , min , max, title) =>{
  const num = parseInt(input , 10)
  
  // Check if the input is positive
  if (num < min && num > max) {
    alert(`invalid ${title}! 2`) ;
    return false;
  }
  // Check if the input is rounded to an integer
  if (Math.round(num) !== num ) {
    alert(`invalid ${title}! 3`) ;
    return false;
  }
  // If all the conditions are met, return true
  return true;
}




export const separate_string = (str)=> {
 
  const str1 = str.slice( 0, 2 );
  const str2 = str.slice( 2 );
     
     return str1+' '+str2 ;
}


export const generate_random_id=(lmt=24)=>{
       
    let strPlus = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let randomString = ""; 
    
    for (let i=0; i < lmt ; i++) {
      let randomIndex = Math.floor(Math.random() * strPlus.length);
      randomString += strPlus[randomIndex];
    }
    
    return randomString ;
   
  
    
  }

 
  export const generatePin = () =>{
   
    const pin = Math.floor(1000 + Math.random() * 9000);
    return parseInt(pin);

}

 

  export const generate_random_orderId = ( tableNo ) =>{
     
    // use use null to delete uniqueId of a table to prepare the table for next client 
     // usually clicking activate table 
     let strPlus = "0123456789";
    let randomString = ""; 
     if(tableNo){
        
        for (let i=0; i < 3; i++) {
          let randomIndex = Math.floor(Math.random() * strPlus.length);
          randomString += strPlus[randomIndex];
       }
    
    return 'T'+tableNo+randomString ;
   
  } else {
    
        return randomString
    }
    
  }


  export const remove_client_local_storage = () =>{

      // create an array of the keys to remove
      const keys = ['menumelabvvYclientId', 'menumelabvvYtableNo'];
  
      // loop through the array and remove each key
      for (let key of keys) {
        localStorage.removeItem(key);
      }
  }
  


  export const get_first_key = (obj) => {

    // check if the argument is an object
    if (typeof obj === "object" && obj !== null) {

      // get the array of keys from the object
      let keys = Object.keys(obj);
      // return the first element of the array, or undefined if the array is empty
      return keys[0];

    } else {

      // throw an error if the argument is not an object
      throw new Error("Invalid argument: expected an object");

    }
  }


export const apply_ternary = ( value, value2 ) =>{
  return value ? value: value2 ; 
}


export const toFindDuplicates = (arr)=>{

  if(arr.length<0){
      return [] ; 
  }
    
  return arr.filter((element, index) => {
         return arr.indexOf(element) === index;
    })

 }


// A function that takes an object as an argument and returns an array of objects with num and id properties
export const  convert_object_toArray  = (obj , id) => {
  // Get an array of the keys of the object
  const keys = Object.keys(obj);
  // Create an empty array to store the result
  const result = [];
  // Loop through the keys array
  keys.forEach((key) => {
    // Create an object with num and id properties
    const newObj = { ...obj[key], [id]: key };
    // Push the object to the result array
    result.push(newObj);
  });
  // Return the result array
  return result;
};

export const  convert_array_toObject  = (arr) => {
const resultObject = {};

// Iterate over each item in the array
 arr.forEach((item) => {
 // Extract the id (e.g., 'id1', 'id2')
 const keysInArr = Object.keys(item);
// Extract the inner object (e.g., { key: '11', value: '1100' })
  keysInArr.forEach((key)=>{
    const innerObject = item[key];
    // Assign the inner object to the corresponding key in the resultObject
     resultObject[key] = innerObject;
  })
 
   });

    return resultObject ;
}

// used in admintablejs for client order and client payment to include clientId with order or payment object.
export const objWithId2 = (obj, objKey) =>{

                    for (let key in obj ) {
                          let nestedObj = obj[key];
                          for (let prop in nestedObj) {
                               nestedObj[prop][objKey] = key;
                          }
                    }

            
                 // all orders from all clients in a object {key1:{}, key2:{}, ....}
                 // key1 : key of the child obj and value contains the key of the parent obj as {objKey : parentKey}
                return Object.values(obj).reduce((acc, cur) => { return {...acc, ...cur}; }, {});

}

// used in admintablejs for client order and client payment to include clientId with order or payment object.
// all orders from all clients in a object {key1:{}, key2:{}, ....}
// key1 : key of the child obj and value contains the key of the parent obj as {objKey : parentKey}
export const objWithId = ( obj, objKey ) => {
   
  const mergedObj = {};
  for (const [key, values] of Object.entries(obj)) {
    for (const [key2, value] of Object.entries(values)){
      value[objKey] = key ;
      mergedObj[key2] = value ;
    }
  }
  
  
  return mergedObj;
};

export const  addObjectKeyToObjectValue = (obj) => {
  // Initialize an empty array
  let array = [];
  // Loop through the object's keys
  for (let key in obj) {
    // Create a new object with the key and value properties
    let newObj = {... obj[key], key: key, };
    // Push the new object to the array
    array.push(newObj);
  }
  // Return the array
  return array;
}



export const showTotalPrice = ( arr , tableNo ) => {
 
  let sum = 0 ;
  


  
  const filteredArr = arr.filter(order=> order[1].tableNo === parseInt( tableNo ) ) ;  // check change tableNo with tableId
  
  filteredArr.forEach(ele => {
          
     // Check if the object has the property
       ele[0].forEach((ele2 , i)=>{

        if (ele2.hasOwnProperty('productSizePriceInfo')) {
          // Add the property value to the sum
          
          sum +=  keep_numbers(ele2.productSizePriceInfo.value.value1) ;
        }else{
          sum= 0 ;
        }

       }) 
  
   })
   
   return parseInt(sum*100) ;
}


export const showTotalPriceSingleOrder = ( arr , tableNo ) => {
 
  let sum = 0 ;
 
  const filteredArr = arr.filter(order=> order.tableNo === parseInt( tableNo ) ) ;  // check change tableNo with tableId
  
  filteredArr.forEach(ele => {
          
    

        if (ele.hasOwnProperty('productSizePriceInfo')) {
          // Add the property value to the sum
          
          sum +=  keep_numbers(ele.productSizePriceInfo.value.value1) ;
        }else{
          sum= 0 ;
        }

  }) 
      
   return parseInt(sum*100) ;
}


export const convertCents = (pennies) =>{
  // Divide the pennies by 100 to get the pounds
  let pounds = pennies / 100;
  // Format the pounds with 2 decimal digits
  let formatted = pounds.toFixed(2);
  // Add a pound sign and a "p" suffix
  return formatted ; 
}


export const convertToCents = (amountInEuros) => {
  // Convert the amount to cents (integer)
  const cents = Math.round(amountInEuros * 100);
  return cents;
};


export const  roundPositive = (number) => {

  // parse the string as a decimal number
  let parsed = parseInt(number, 10);
  
  // check if the parsing was successful
  if (isNaN(parsed)) {

    // return NaN if the input was not a valid number
    return NaN;

  } else {

    // return the absolute value of the rounded number
    return Math.abs(Math.round(parsed));

  }
}


export const capitalizeAndRemoveWhitespace = (inputString)=> {
  // Remove white spaces
  const stringWithoutSpaces = inputString.replace(/\s+/g, '');
  // Capitalize the string
  const capitalizedString = stringWithoutSpaces.charAt(0).toUpperCase() + stringWithoutSpaces.slice(1);
  return capitalizedString;
};


export const getImageUrl = ( adminId=null ,foldername, filename, token )=>{

  const id = adminId || id_from_url() ;
  const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/smenu-746d5.appspot.com/o/';

  // const encodedPath = encodeURIComponent(`${folder}/${filename}`);
  // const encodedPath = encodeURIComponent(`${filename}`);
  // 4l6HyuUe5USCuOB6eZMchFggGbY2%2FprofileImage%2F30.jpg

  const imageUrl = `${baseUrl}${id}%2F${foldername}%2F${filename}?alt=media&token=${token}`;
  
  return imageUrl;

}


export const getImageUrlAsync = async (adminId = null, foldername, filename, token) => {
  try {
    const id = adminId || id_from_url();
    const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/smenu-746d5.appspot.com/o/';

    const imageUrl = `${baseUrl}${id}%2F${foldername}%2F${filename}?alt=media&token=${token}`;
    return imageUrl;
  } catch (error) {
    // Handle any errors here
  
    return null;
  }
};



export const  extractTokenFromUrl =(url) =>{

  const queryString = url.split('?')[1]; // Split by '?'
  const params = new URLSearchParams(queryString);
  const token = params.get('token'); // Get the value of 'token'
  return token;

}


// this is an advance version of arrane_order, as it orderIds in clientId, 
// we may use this later , check change keep it 
const test = (data)=>{
  let orderStatusTrue = [] ; 
  let arr =[] ;
  const result = Object.entries(data).map(([user, info]) => {
    // get the key and value of the info object
    const [infoKey, infoValue] = Object.entries(info)[0];
    // return a new object with the user, info, and name properties

    const data= {
      ...infoValue,
      clientId: user,
      orderKey: infoKey,
      
    };

    const filteredData = Object.keys(infoValue)
                        .filter(key => isNaN(key)===false)
                        .reduce((obj, key) => {
                            obj[key] = infoValue[key];
                               return obj;
                         }, {});


                         const keyArr= Object.keys(filteredData) ;  
                         const arrForEachProductType=[];
         
                         keyArr.map((element)=>{
                          
                            filteredData[element]['orderKey'] = element ;
                            filteredData[element]['orderId']  = data.orderId ;
       
                           //check if filteredData can be used instead of element_2: 
       
                            arrForEachProductType.push(infoValue[element]['producType']) ;
                         
                         
                         })
                         
         
                           const data4= Object.values(filteredData) ;
                           
                        

                         return   [ data4, 
                      
                            { orderId: data.orderKey, 
                              orderNumber: data.orderNumber, 
                              orderStatus:  orderStatusTrue.length === keyArr.length ? true : false, 
                              tableNo: data.tableNo, 
                              tableId: data.tableId, 
                              // clientId: Array.isArray(data.clientId) ? [...data.clientId] : [data.clientId]  , 
                              uniqueOrderId : data.uniqueOrderId,
                              orderedTime: data.orderedTime ,
                              clientId: data.clientId
                            },
                                   
                              arrForEachProductType ] 
                              
                           ;





   
  });
   return result ;
}

export const arrange_order = ( orderObj ) =>{
    
  if( Object.keys(orderObj).length>0 ){

  
  const objValArr = Object.values( orderObj ) ;
  const objKeyArr = Object.keys( orderObj ) ;
 
  const arr = [] ;
  let orderStatusTrue = [] ;

  objValArr.forEach((element_2 , index)=>{
      
   const data = {...element_2, 
                  tableId: element_2.tableId,
                  orderId: objKeyArr[index],
                  orderedTime: element_2.orderedTime
                }
      
      
    

    const filteredData = {...element_2.singleOrder} ;

           

                  //old way: 

                  // const filteredData2 = Object.keys(element_2)
                  //     .filter(key => isNaN(key)===false)
                  //     .reduce((obj, key) => {
                  //         obj[key] = element_2[key];
                  //            return obj;
                  //      }, {});

                  

                // orderStatusTrue = Object.values(filteredData).filter(ele=>  ele.orderStatus === true ) // old code

                // if orderStatus does not exists in the database then this: !e.orderStatus as undefined
                orderStatusTrue = Object.values(filteredData).filter(ele=> ele.productInfo ? ele.productInfo.some((e)=> e.orderStatus=== false || !e.orderStatus) : false )
                

                const keyArr= Object.keys(filteredData) ;  
                const arrForEachProductType=[];

                keyArr.map((element)=>{
                 
                   filteredData[element]['orderKey'] = element ;
                   filteredData[element]['orderId']  = data.orderId ;

                  //check if filteredData can be used instead of element_2: 

                   arrForEachProductType.push(element_2.singleOrder[element]['sectionType']) ;
                
                
                })
                

                  const data4= Object.values(filteredData) ;
                  
                  
                  arr.push( [ data4, 
                    
                              { 
                                orderId: data.orderId, 
                                // orderNumber: data.orderNumber, 
                                // orderStatus:  orderStatusTrue.length === keyArr.length ? true : false, 
                                orderStatus: orderStatusTrue.length >0 ? false : true, 
                                tableNo: data.tableNo, 
                                tableId: data.tableId, 
                                // clientId: Array.isArray(data.clientId) ? [...data.clientId] : [data.clientId]  , 
                                uniqueOrderId : data.uniqueOrderId,
                                createdAt: data.orderCreatedAt 
                              },
                                     
                                arrForEachProductType ] 
                                
                            ) ;
                 
                 
              
 }) 
  
 return arr ; 

 }else{
  return [] ; 
 }
 
}

 
// Define a function that takes a string as a parameter
 export const keep_numbers = (string) => {
    // Initialize a variable to store the result
    let result = "";
    // Loop through the string
    for (let i = 0; i < string.length; i++) {
      // Check if the character is a digit
      if ((string[i] >= "0" && string[i] <= "9") || string[i]==="." || string[i] ===",") {
        // Append the digit to the result
        result += string[i];
      }
    }
    // Return the result
   
    return Number( result );
  }



export const capitalize_and_trim = (input) => {
    // convert the input to a string
    input = String(input);
    // remove all whitespace characters from both ends of the input
    input = input.trim();
    // replace all whitespace characters within the input with an empty string
    input = input.replace(/\s+/g, '');
    // convert the input to uppercase
    input = input.toUpperCase();
    // return the modified input
    return input;
  }
  
  // this fn sort array element in ascending order, and most array contain object as element so we 
  // sort this array with a particular key : 
  export const  sort_array_in_ascending_order = (arr , key=null) => {
    // use the built-in sort method with a custom compare function
    arr.sort( (a, b) => {
      // compare two elements and return a negative number if a < b, zero if a == b, or a positive number if a > b
      return a[key] - b[key];
    });
    // return the sorted array
    
    return arr;
  }

  // sort the duplicate item of an array: 
  export const findDuplicates = (chars)=>{
  
    return chars.filter((element, index) => {
      
       return chars.indexOf(element) === index;
     })
}


export const id_from_url = () => {

  let url = window.location.href; // get the full URL
  let query = new URLSearchParams(url.split("?")[1]); // parse the query string
  let id = query.get("id"); // get the value of "id"
   return id ; 

} 

export const table_from_url = () => {

  let url = window.location.href; // get the full URL
  let query = new URLSearchParams(url.split("?")[1]); // parse the query string
  let table = query.get("table"); // get the value of "id"
  
   return table; 

}



// this fn is used to show products without perticular product type on the top of a section. 
export const sortArrayBySectionName = (arr, sectionName)=> {
  // Separate elements with city "Geneva" and others
  const genevaElements = arr.filter(item => item === sectionName);
  const otherElements = arr.filter(item =>  item !== sectionName);
  
  // Combine the arrays to get the desired order
  return [...genevaElements, ...otherElements];
}


export const get_search_data = async ( dbTable ) => {
       
  // Get the database reference using the ref function
  const dbref = ref(db, dbTable);

    try {
       const data = await get(dbref) ;
              
            return data
    } catch (error) {
      throw new Error(`Failed to get data from ${dbTable}: ${error.message}`, error);
        //  throw error;
    }
    
  };


  

export const  dbref_fn = (databaseTable, path = null  ) => {
   const key = id_from_url() ;
   let id = key ? key : localStorage.getItem('userId');  // check change 

  // Check if the database table and user id are valid
  if (!databaseTable || !id) {
    
     alert( 'Please login and try again...' )
    return
  }

  // Create the database path using template literals
  const databasePath = `${databaseTable}/${id}${path ? `/${path}` : ''}`;
   
      // alert(databasePath);
  // Get the database reference using the ref function
  const databaseReference = ref(db, databasePath);

  // Return the database reference
  return databaseReference;
}



// A service function that gets the subscription data of a company from Firebase and sends it to Stripe
export const  get_subscription_data = async ( id ) => {
  
  try {
    // Get the data from Firebase using the get_data function
    const data = await readData('company', get, undefined , undefined, undefined, id, undefined, undefined, 'subscription');
    // Check if the data exists
    if (data) {
    
      const subscriptionId = data.sub_id;
      const customerId = data.id;
      // Send the data to Stripe using the instance.post method
      const res_2 = await instance.post('/subscribed-customer', {subscription_id: subscriptionId, customer_id: customerId}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // Return the customer object from Stripe
      const customer = res_2.data;
      return customer;
    } else {
      // Throw an error if the data does not exist
      throw new Error('No subscription data found');
    }
  } catch (error) {
    // Handle any errors
   // Throw an error if the data does not exist
   throw new Error('No subscription data found');
  }
};








export const readData = async (collectionName, operationName, tryCallback=()=>{}, catchCallback= ()=>{}, errosMessage=null, adminId= null, obj1={}, obj2={}, path = null ) => {
  // get the user id from the url or use the admin id
  
  const userId = adminId || id_from_url();
  
  // check if the required parameters are valid
  if (!collectionName  || !userId) {
    // alert("Fail to read data, please login and try again!");
    return;
  }

  // construct the database path using template literals
  const dbPath = `${collectionName}/${userId}${path ? `/${path}` : ""}`;
  
  // get the database reference
  const dbref = ref(db, dbPath);

  // get the firebase function
  const firebaseFunction = operationName(dbref);

  try {
    // call the function with the data
    const snapshot = await firebaseFunction;

   

    // call the try callback with the result using arrow function
    tryCallback(snapshot.val() , obj1 );
     
    return snapshot.val() ;  //will it create any problem?
    
   } catch (error) {
    // handle the error
    
    // console.error(error);

    // call the catch callback with the error using arrow function
    catchCallback(errosMessage , obj2);
    throw error ;
  }
};



export const writeData = async (collectionName, operationName, data={}, tryCallback=()=>{} , catchCallback=()=>{}, errorMessage,  adminId = null, path = null, obj1={} , obj2={} ) => {
  // get the user id from the url or use the admin id
  const userId = adminId || id_from_url();
 
  // check if the required parameters are valid
  if (!collectionName || !userId) {
    alert("Please login and try again...");
    return;
  }

   // check if the required parameters are valid
   if (operationName!=='remove' && !data ) {
    alert("Please put some data, and try again! ");
    return;
  }

  // construct the database path using template literals
  const dbPath = `${collectionName}/${userId}${path ? `/${path}` : ""}`;
  
  // get the database reference
  const dbref = ref(db, dbPath);

  const firebaseFunction = operationName(dbref, data);

  try {


    // call the function with the data
    const snapshot = await firebaseFunction;

    // call the try callback with the 
    tryCallback(obj1);
    
   

  } catch (error) {

    // handle the error
    // console.error(error);
     
    // call the catch callback with the error using arrow function
    catchCallback( errorMessage, obj2 );
    throw error ; 
    // throw new Error(error);  which one is more suitable?
  }
};


// we don not use this: 
export const updateState = (callback, obj) => {
  // Check if the callback is a function
  if (typeof callback === "function") {
    // Check if the object has any properties
    if (Object.keys(obj).length > 0) {
      // Call the callback with the object
      callback(obj);
    }
  } else {
    // Throw an error or handle it somehow
    alert("Please contact service provider to fix this error!") ;
  }
};



