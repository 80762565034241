import React from 'react';
import instance from '../../axiosBase';
import burgerLogo from '../../assets/images/burger-logo.png';
import classes from './Logo.css';

const logo = (props) => {
  
  
  return (
   <div className={classes.Logo_wrap}>
    <div className={classes.Logo} style={{height: props.height}}>
      <h1 className={classes.Logo_text}>MENUMELA</h1>
      {/* <p className={ classes.Logo_s+ ' '+ classes.neumorphism }>S</p>  */}
      {/* <p className={ classes.Logo_m+ ' '+ classes.neumorphism  }>M</p> 
      <p className={ classes.Logo_e+ ' '+ classes.neumorphism }>E</p> 
      <p className={ classes.Logo_n+ ' '+ classes.neumorphism }>N</p> 
      <p className={ classes.Logo_u+ ' '+ classes.neumorphism  }>U</p> 
      <p className={ classes.Logo_m+ ' '+ classes.neumorphism  }>M</p> 
      <p className={ classes.Logo_e+ ' '+ classes.neumorphism }>E</p> 
      <p className={ classes.Logo_n+ ' '+ classes.neumorphism }>L</p> 
      <p className={ classes.Logo_u+ ' '+ classes.neumorphism  }>A</p>  */}
    </div>
    <small className={classes.Logo_title}> For smart ordering </small>
    
  </div> 
)};

export default logo;