
import { apply_ternary, readData } from "./utility";
import { get } from "firebase/database";





// export const getCompanyData = (adminId, onCallback) =>{

//     readData( 'companies' , get , undefined, undefined, undefined , adminId ) 
    
//       .then((companiesData)=>{
//        const  obj =  {
//                      accountActivation: apply_ternary( companiesData.accountActivation, false ),
//                      accountName: apply_ternary( companiesData.accountName, "" ),
//                      city: apply_ternary( companiesData.city, "" ),
//                      companyName: apply_ternary( companiesData.companyName, "" ),
//                      country: apply_ternary( companiesData.country, "" ),
//                      currency: apply_ternary( companiesData.currency, null ),
//                      imageName: apply_ternary( companiesData.imageName, "" ),
//                      imageToken: apply_ternary(companiesData.imageToken, ""),
//                      paymentSystemStatus: apply_ternary(companiesData.paymentSystemStatus, false),
//                      postCode: apply_ternary( companiesData.postCode, ""),
//                      productEditingMode: apply_ternary( companiesData.productEditingMode, false ),
//                      streetName: apply_ternary( companiesData.streetName, "" ),
//                      streetNumber: apply_ternary( companiesData.streetNumber, "" )
            
//         }
          
              
//                    onCallback(obj)
           
     
//       })
//       .catch(()=>{
//         alert('Unable to get company data! ')
//       })


// }



export const getCompanyData = async (adminId) => {
  try {
      const companiesData = await readData('companies', get, undefined, undefined, undefined, adminId);
      const obj = {
          accountActivation: apply_ternary(companiesData.accountActivation, false),
          accountName: apply_ternary(companiesData.accountName, ""),
          city: apply_ternary(companiesData.city, ""),
          companyName: apply_ternary(companiesData.companyName, ""),
          country: apply_ternary(companiesData.country, ""),
          currency: apply_ternary(companiesData.currency, null),
          imageName: apply_ternary(companiesData.imageName, ""),
          imageToken: apply_ternary(companiesData.imageToken, ""),
          paymentSystemStatus: apply_ternary(companiesData.paymentSystemStatus, false),
          postCode: apply_ternary(companiesData.postCode, ""),
          productEditingMode: apply_ternary(companiesData.productEditingMode, false),
          streetName: apply_ternary(companiesData.streetName, ""),
          streetNumber: apply_ternary(companiesData.streetNumber, "")
      };

      return obj;
  } catch (error) {
    throw new Error('Fail to get company data!');
  }
};