import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';



const initialState = {
  totalAmount: 0,
  tip: 0  
};

const paymentStart = ( state, action ) => {
    return updateObject( state, { totalAmount: action.totalAmount, tip: action.tip } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.PAYMENT_START: return paymentStart(state, action);
        default:
            return state;
    }
};

export default reducer;