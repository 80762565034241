import React, { Component, Suspense } from 'react';

import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import asyncComponent from './hoc/asyncComponent/asyncComponent';

import Layout from './hoc/Layout/Layout';

import Logout from './containers/Auth/Logout/Logout'; 

import * as actions from './store/actions/index';




// Lazy load components
const AsyncAuth = React.lazy(() => import('./containers/Auth/Auth'));
const AsyncProduct = React.lazy(() => import('./containers/Admin/Product/Products'));
const AsyncDashboard = React.lazy(() => import('./containers/Admin/admin/Dashboard'));
const AsyncAllPayments = React.lazy(() => import('./containers/Admin/Payment/AllPayments'));
const AsyncAdminTables = React.lazy(() => import('./containers/Admin/Product/AdminTable/AdminTables'));
const AsyncHomePage = React.lazy(() => import('./components/HomePage/HomePage'));


class App extends Component {


 componentDidUpdate(){
 
 }
 


  componentDidMount () {
   
      // this keeps a user logged in : 
      this.props.onAuthCheckState( this.props.onCompanyData); 

  
      
  }


  

  render () {
  
  //  console.log(this.props)

    let routes ="";
    
  
    if (  this.props.adminId  ) {
        routes = ( 
          <Switch>
            
            <Route path="/auth" render={() => (
              <Suspense fallback={<div>Loading...</div>}>
                  <AsyncAuth />
             </Suspense>
            )} />

            <Route path="/payments" render={(props) => (
              <Suspense fallback={<div>Loading...</div>}>
                  <AsyncAllPayments adminId={this.props.adminId} currency={this.props.currency} {...props}  />
             </Suspense>
            )} />

            <Route path="/dashboard" render={(props) => (
              <Suspense fallback={<div>Loading...</div>}>
                  <AsyncDashboard adminId = { this.props.adminId } {...props}  />
             </Suspense>
            )} />

            <Route path="/tables" render={(props) => (
              <Suspense fallback={<div>Loading...</div>}>
                  <AsyncAdminTables adminId = { this.props.adminId } {...props}  />
             </Suspense>
            )} />

             <Route path="/logout" render={(props) => (
              <Suspense fallback={<div>Loading...</div>}>
                  <Logout adminId = { this.props.adminId } {...props}  />
             </Suspense>
            )} />

           <Route path="/" render={(props) => (
              <Suspense fallback={<div>Loading...</div>}>
                  <AsyncProduct adminId = { this.props.adminId } {...props}  />
             </Suspense>
            )} />

           <Redirect to="/" />


            {/* <Route path="/payments"   render={(props) => < AsyncAllPayments adminId={this.props.adminId} {...props} />}  /> */}
            {/* <Route path="/dashboard"    render={(props) => <AsyncDashboard adminId = { this.props.adminId } {...props} />} /> */}
            {/* <Route path="/tables"   render={(props) => <AsyncAdminTables adminId = { this.props.adminId } {...props} />}  /> */}
            {/* <Route path="/logout"   render={(props) => <Logout adminId = { this.props.adminId }  {...props} />}  /> */}
            {/* <Route path="/"    render={(props) => <AsyncProduct adminId = { this.props.adminId } {...props} />} /> */}
            
            
            {/* <Redirect to="/" /> */}
          
            {/* <Route path="/ross"  exact render={(props) => <Homepage {...props} />}  /> */}
           
           
             {/* <Redirect to={{pathname: '/menu?id=12' , state:{id: this.state.userId}}} /> */}
 
          </Switch> 
        );

    }else{

      routes = (  
        <Switch>
            
            <Route path="/auth" render={() => (
              <Suspense fallback={<div>Loading...</div>}>
                  <AsyncAuth />
             </Suspense>
            )} />

            <Route path="/:menu" render={(props) => (
              <Suspense fallback={<div>Loading...</div>}>
                  < AsyncProduct {...props} />
             </Suspense>
            )} />

            <Route path="/" exact render={(props) => (
              <Suspense fallback={<div>Loading...</div>}>
                  < AsyncHomePage {...props} />
             </Suspense>
            )} />
           <Redirect to="/" />

        </Switch>
      );
    
    }
    // else{
    //   routes = (
    //     <Switch>
         
    //         {/* <Route path="/auth"   render={(props) => <AsyncAuth  {...props} />} /> this works , will use this finally */}
    //         <Route path="/auth"   component = {AsyncAuth} /> 
    //         <Route path="/" exact  render={(props) => <Homepage  {...props} />} />
    //        {/* <Route path="/auth"   render={(props) => <Auth  {...props} />} />   */}
    //        {/* <Route path="/" exact component={Products} /> */}
    //        {/* <Redirect to="/" /> */}
    //     </Switch>
    //   )
    // }

    return (
      <div>
        <Layout isAdmin = {this.props.adminId}  isAuthenticated = { this.props.adminId } >
           
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    adminId: state.auth.userId ,
    currency: state.auth.data.currency
  };
};
 
const mapDispatchToProps = dispatch => {
  return {

    onAuthCheckState: () => dispatch( actions.authCheckState() ), 
    onCompanyData: ( obj ) => dispatch( actions.companyData( obj ) )
    
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );





// things to do :
// if we click logout out this.state still has the client id , make it null , use logout fn with the help of redux, 















